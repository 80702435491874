<template>
  <div class="downloadlibrary">
    <MyheadCompnent />
    <el-row :gutter="0">
      <el-col :span="1"></el-col>
      <el-col :span="22">
        <h2>Downloads</h2>
        <div style="font-size: 20px">
          <div>
            metabolites
            <el-link download='metabolites.zip' :href="this.$store.state.downloadURL + 'metabolite_id.zip'"
              target="_blank">
              <Download style="width:20; height:20" />
            </el-link>
          </div>
          <div>
            <i>in-silico</i> MS/MS Library
            <el-link download='MS.zip' :href="this.$store.state.downloadURL + 'MS2Library.zip'" target="_blank">
              <Download style="width:20; height:20" />
            </el-link>
          </div>
          <div>
            features in 10 species
            <el-link download='featues in 10 speices.zip'
              :href="this.$store.state.downloadURL + 'featues in 10 speices.zip'" target="_blank">
              <Download style="width:20; height:20" />
            </el-link>
          </div>
          <div>
            reactions
            <el-link download='reactions.zip'
              :href="this.$store.state.downloadURL + 'reaction_pathway_network5_libraryFilter.zip'" target="_blank">
              <Download style="width:20; height:20" />
            </el-link>
          </div>
          <div>
            KO_reaction
            <el-link download='ko_reaction.zip' :href="this.$store.state.downloadURL + 'ko_reaction.zip'"
              target="_blank">
              <Download style="width:20; height:20" />
            </el-link>
          </div>
          <div>
            <div>
              wheat_gene_annotation
              <el-link download='wheat_gene_annotation.zip'
                :href="this.$store.state.downloadURL + 'wheat_annotation2.zip'" target="_blank">
                <Download style="width:20; height:20" />
              </el-link>
            </div>
            <div>
              wheat_gene_KO
              <el-link download='wheat_gene_KO.zip' :href="this.$store.state.downloadURL + 'wheat_gene_KO.zip'"
                target="_blank">
                <Download style="width:20; height:20" />
              </el-link>
            </div>
            <div>
              wheat_transcript_tpm
              <el-link download='wheat_transcript_tpm.zip'
                :href="this.$store.state.downloadURL + 'wheat_transcript_tpm.zip'" target="_blank">
                <Download style="width:20; height:20" />
              </el-link>
            </div>
          </div>
          <div>
            <div>
              maize_gene_annotation
              <el-link download='maize_gene_annotation.zip'
                :href="this.$store.state.downloadURL + 'maize_annotation2.zip'" target="_blank">
                <Download style="width:20; height:20" />
              </el-link>
            </div>
            <div>
              maize_gene_KO
              <el-link download='maize_gene_KO.zip' :href="this.$store.state.downloadURL + 'maize_gene_KO.zip'"
                target="_blank">
                <Download style="width:20; height:20" />
              </el-link>
            </div>
            <div>
              maize_transcript_tpm
              <el-link download='maize_transcript_tpm.zip'
                :href="this.$store.state.downloadURL + 'maize_transcript_tpm.zip'" target="_blank">
                <Download style="width:20; height:20" />
              </el-link>
            </div>
          </div>
          <div>
            <div>
              rice_gene_annotation
              <el-link download='rice_gene_annotation.zip'
                :href="this.$store.state.downloadURL + 'rice_annotation2.zip'" target="_blank">
                <Download style="width:20; height:20" />
              </el-link>
            </div>
            <div>
              rice_gene_KO
              <el-link download='rice_gene_KO.zip' :href="this.$store.state.downloadURL + 'rice_gene_KO.zip'"
                target="_blank">
                <Download style="width:20; height:20" />
              </el-link>
            </div>
            <div>
              rice_transcript_tpm
              <el-link download='rice_transcript_tpm.zip'
                :href="this.$store.state.downloadURL + 'rice_transcript_tpm.zip'" target="_blank">
                <Download style="width:20; height:20" />
              </el-link>
            </div>
          </div>
          <div>
            <div>
              tomato_gene_annotation
              <el-link download='tomato_gene_annotation.zip'
                :href="this.$store.state.downloadURL + 'tomato_annotation2.zip'" target="_blank">
                <Download style="width:20; height:20" />
              </el-link>
            </div>
            <div>
              tomato_gene_KO
              <el-link download='tomato_gene_KO.zip' :href="this.$store.state.downloadURL + 'tomato_gene_KO.zip'"
                target="_blank">
                <Download style="width:20; height:20" />
              </el-link>
            </div>
            <div>
              tomato_transcript_tpm
              <el-link download='tomato_transcript_tpm.zip'
                :href="this.$store.state.downloadURL + 'tomato_transcript_tpm.zip'" target="_blank">
                <Download style="width:20; height:20" />
              </el-link>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="1"></el-col>
    </el-row>

    <div>
      <el-row>
        <el-col :span="2">
        </el-col>
        <el-col :span="18">
          <h3 align='center'>Welcome to PMhub</h3>
          <p>The Plant Metabolite hub (PMhub) is a freely available electronic database containing detailed information
            about small molecule metabolites in nature. The database is designed to contain chemical data, mass spectra
            data, reaction data and GWAS data for several crops, such as Oryza sativa, Zea mays, Triticum aestivum,
            Lycopersicon esculentum.</p>
        </el-col>
        <el-col :span="4">
          <div style="text-align: center;">
            <img src="@/assets/img/home3.svg" width="90">
            <img src="@/assets/img/home1.svg" width="80">
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  import MyheadCompnent from '@/components/Common/MyheadCompnent.vue'
  import MetaboliteBrowseCompnentSearchHome from '@/components/Browse/MetaboliteBrowseCompnentSearchHome.vue'
  import { Download } from '@element-plus/icons-vue'
  export default {
    name: 'DownloadLibrary',
    methods: {
      search (selectItem, searchItem) {
        this.$router.push({ name: 'MetaboliteSearch', params: { selectItem: selectItem, searchItem: searchItem } })
      }
    },
    components: {
      MyheadCompnent,
      MetaboliteBrowseCompnentSearchHome,
      Download,
    }
  }
</script>

<style scoped lang="scss">
  .main {
    background-image: url('../assets/img/home2.svg');
    background-repeat: no-repeat;
    background-color: rgb(241, 241, 241);
    background-size: cover;
    background-position: center;
    filter: blur(0.8px);
    height: 760px;
    text-align: center;
    line-height: 600px;
  }

  .main:hover {
    filter: blur(0px);
  }

  .content-search {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 160px;
    width: 780px;
    text-align: center;
  }

  .content-search2 {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    /* height: 160px;
    width: 780px; */
    text-align: center;
  }

  li {
    float: left;
    padding: 2em;
    list-style: None;
    cursor: pointer;
    font-size: 7px;
  }

  .librarycount {
    padding: 1em;
    list-style: None;
    font-size: 14px;
    display: inline-block;
  }

  .librarycount2 {
    padding: 1em;
    list-style: None;
    font-size: 20px;
    display: inline-block;
  }

  li.liclass:hover {
    color: rgb(229, 107, 7);
  }
</style>